<template>
  <div id="tentang">
    <div class="pb-[50px] pt-[50px] lg:pt-[100px] 2xl:px-60 flex-container bg-[#A61E1E]">
      <div class="flex flex-wrap p-3">
        <div data-aos="fade-down" data-aos-duration="500" data-aos-offset="300" class="w-full lg:w-1/2">
          <img src="@/assets/Display/slide-min-1.jpg" alt="catra" class="w-[500px] rounded-xl mx-auto" />
        </div>
        <div data-aos="fade-up" data-aos-duration="500" data-aos-offset="300" class="my-auto px-5 w-full lg:w-1/2">
          <div class>
            <h1 class="mb-5 text-lg text-bodas text-left font-normal">
              About Us
            </h1>
          </div>
          <div class="text-justify">
            <h2 class="text-bodas text-md font-light">
              Chiclin merupakan makanan berbasis Taiwan Street Food yang menawarkan menu daging ayam diiris tipis dengan
              dibalut tepung, kemudian digoreng dan disajikan dengan cara dipotong, serta diberi bumbu tabur, Kami
              adalah bagian keluarga besar Chiclin chicken yang berpusat di ciparay. Dengan pengalaman yang luas dalam
              membuka gerai di berbagai negara dan penyesuaian menu dengan selera lokal, Chiclin chicken Street Snacks
              terus bertumbuh dan menjadi salah satu merek makanan jalanan Taiwan yang paling populer di Indonesia,
              salah satunya terletak di ciparay
            </h2>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap p-3 pt-[50px]">
        <div data-aos="fade-down" data-aos-duration="500" data-aos-offset="300" class="my-auto px-5 w-full lg:w-1/2">
          <div class>
            <h1 class="mb-5 text-lg text-bodas text-left font-normal">
              THE SIGNATURE OF CHICLIN
            </h1>
          </div>
          <div class="text-justify">
            <h2 class="text-bodas text-md font-light">
              Chiclin Ciparay juga berkomitmen untuk menjaga standar kebersihan dan keamanan makanan yang tinggi. Hal ini tercermin dari penggunaan bahan-bahan segar yang berkualitas tinggi dan pengolahan makanan yang bersih dan aman untuk konsumsi.
            </h2>
          </div>
        </div>
        <div data-aos="fade-up" data-aos-duration="500" data-aos-offset="300" class=" w-full lg:w-1/2">
          <img src="@/assets/Display/slide-min-2.jpg" alt="catra" class="w-[400px] rounded-xl mx-auto" />
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/tailwind.css";

export default {};
</script>

<style></style>