<template>
  <div id="footer">
    <div
      class="pt-[100px] lg:pt-[0px] lg:px-40 flex-container bg-[#F7F7E6]">
      <div class="flex flex-wrap">
        <div class="w-full self-start lg:w-1/2">
          <div class="ml-[80px] lg:ml-0 lg:mt-20">
            <img src="@/assets/logocik.png" alt="catra" class="max-w-full mb-5 lg:mx-auto" width="250" />
          </div>
          <div class="flex gap-3 justify-center">
            <div class>
              <a href="https://www.instagram.com/chiclin.ciparay?igsh=amlpOHNtMWM5NHIw" target="_blank">
                <img src="../assets/insta.png" alt="catra"
                  class=" max-w-full transform hover:scale-125 duration-300" />
              </a>
            </div>
            <div class>
              <a href="https://wa.link/jacikr" target="_blank">
                <img src="../assets/whats.png" alt="catra"
                  class=" max-w-full transform hover:scale-125 duration-300" />
              </a>
            </div>
          </div>
          <div class="grid grid-cols-4 lg:grid-cols-3">

          </div>
        </div>
        <div class="w-full self-start pl-0 mt-4 lg:w-1/2">
          <div class="mt-10 lg:mt-10">
            <img src="@/assets/Logo-Chiclin.png" alt="catra" class="rounded-xl max-w-full mx-auto w-[300px] mb-[0px] lg:w-[200px]" />
          </div>
        </div>
        <hr class="w-[1221px] h-[1px] mx-auto mt-10 mb-5 border-0 bg-hideung" />
      </div>
      <a href="https://strategis.co.id/"
        target="_blank" class="text-base text-center text-hideung transition duration-100 ease-in-out hover:underline">
        <h2 class="mb-1">Copyright © 2024 PT. Keberlanjutan Strategis Indonesia</h2>
      </a>
      <br />
    </div>

    <!-- <div class="bg-cover bg-center bg-no-repeat h-[700px] bg-cats pt-0">
        <div class="grid grid-cols-2 gap-10 pt-15 h-[700px]">
          <div class="text-left p-60">
            <p class="text-4xl font-extrabold">
              SCIENTIFICS
              <a class="text-primary">RESEARCH</a>
            </p>
            <p class="text-4xl font-extrabold">
              JOURNAL
            </p>
            <a href="https://api.whatsapp.com/send/?phone=6281120011644&text&type=phone_number&app_absent=0">
            <button
              type="button"
              class="text-bodas bg-primary hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-primary dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >Consultation NOW !</button>
          </a>
          </div>
          <div class="place-content-center">
            <div class>
              <img class="h-[629px] w-[629px]" src="../assets/home1.png" alt="homie" />
            </div>
          </div>
        </div>
    </div>-->
  </div>
</template>

<script>
import "@/assets/tailwind.css";

export default {};
</script>

<style></style>