<template>
    <div id="location">
        <div class="pb-[50px] pt-[20px] lg:pt-[10px] 2xl:px-60 flex-container bg-[#A61E1E] rounded-xl">
            <div data-aos="zoom-in" data-aos-duration="500" data-aos-offset="300" class="">
                <div 
                class="pt-[30px] text-center text-bodas text-lg font-normal">
                <span class="inline-flex items-baseline"><span>LOCATION</span>
                    <img src="@/assets/LOGO/DARUMA.png" alt="" class="self-center w-10 rounded-xl mx-1" />
                </span>
            </div>
            <div class="flex flex-wrap">
                <div
                    class="my-auto mx-auto lg:w-1/1">
                    <div class="w-full p-3 mt-4">
                        <div class="mt-0 lg:mt-0">
                            <iframe class="rounded-xl w-[350px] h-[400px] lg:w-[500px] lg:h-[400px]"
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15838.8768064295!2d107.7116028!3d-7.0422488!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68c13b99297145%3A0xdbaa8a5ef3b1134a!2sChiclin%20Ciparay!5e0!3m2!1sid!2sid!4v1714992126025!5m2!1sid!2sid"
                                style="border:0;" allowfullscreen="" loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import "@/assets/tailwind.css";

export default {};
</script>

<style></style>