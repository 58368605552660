<template>
  <div id="about">
    <div class="pb-[50px] pt-[50px] lg:pt-[100px] 2xl:px-60 flex-container bg-[#A61E1E] rounded-b-full">
      <div class="flex flex-wrap p-3">
        <div data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="500" class="w-full lg:w-1/1">
          <img src="@/assets/Display/welcome1.jpg" alt="catra" class="rounded-xl max-w-full mx-auto w-[350px] mb-[50px] lg:w-[600px]" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/tailwind.css";

export default {};
</script>

<style></style>