<template>
    <div id="about">
        <div class="pb-[0px] pt-[50px] lg:pt-[50px] 2xl:px-0 flex-container">
            <div class="flex flex-wrap p-3">
                <div data-aos="zoom-in" data-aos-duration="500" data-aos-offset="300" class="w-full lg:w-1/1">
                    <img src="@/assets/overview1.png" alt="catra"
                        class=" max-w-full mx-auto w-full rounded-xl" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import "@/assets/tailwind.css";

export default {};
</script>

<style></style>